<script>
	import { _ } from 'svelte-i18n';
	import zenpassLogo from '$lib/assets/zenpass-logo.svg';
</script>

<div class="splash-screen-content-box">
	<h2 class="splash-screen-header">{$_('splash.welcome')}</h2>
	<div class="splash-screen-text">
		{$_('splash.description')}
	</div>
	<div class="splash-screen-image-box">
		<img alt="splash-screen-zenpass-logo" class="splash-screen-image" src={zenpassLogo} />
	</div>
	<div class="splash-screen-text">
		{$_('splash.joinUs')}
	</div>
</div>

<style lang="scss">
	@import '$lib/styles/mixins';
	.splash-screen-content-box {
		@include full-height-width;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto 1fr auto;
		.splash-screen-header {
			text-align: center;
			font-weight: bold;
			font-size: 1.2rem;
		}
		.splash-screen-text {
			text-align: center;
		}
		.splash-screen-image-box {
			@include flex-center;
		}
	}
</style>
