<script lang="ts">
	import { onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import PageWrapper from '$lib/components/molecules/PageWrapper.svelte';
	import SplashScreenBox from '$lib/components/organisms/splash-screen/SplashScreenBox.svelte';
	import SplashScreenButton from '$lib/components/organisms/splash-screen/SplashScreenButton.svelte';
	import { handleNftCheck, callApi } from '$lib/utils/api';
	import LoadingScreen from '$lib/components/atoms/LoadingScreen.svelte';
	import { api } from '$lib/stores/generalStore';
	import { nomo } from 'nomo-webon-kit/dist/nomo_api';
	import { languages } from '$lib/stores/languages';
	import { browser } from '$app/environment';
	import { runsAsWebOn } from 'nomo-webon-kit';
	import { waitLocale } from 'svelte-i18n';

	let loading = true;

	onMount(async () => {
		await waitLocale()
		if (browser && !runsAsWebOn()) {
			// Do not display anything in this case
			goto('/server-unavailable');
		}

		try {
			const hasNft = await handleNftCheck();
			if (!hasNft) {
				const messengerRes = await nomo.getMessengerAddress();

				await callApi('GET', '/generate_zenpass', undefined, {
					'messenger-code': messengerRes.messengerAddress,
				});
			}

			// fetch languages
			const languagesRes = await callApi('GET', '/get_languages');
			if(languagesRes) {
				languages.set(languagesRes);
			}

			// Fetch countries
			const countriesResponse = await callApi('GET', '/get_countries');
			if (countriesResponse) {
				api.update(store => ({ ...store, countries: countriesResponse }));
			}

			// Fetch kycServices (assuming there's an endpoint for this)
			const kycServicesResponse = await callApi('GET', '/get_kyc_services');
			if (kycServicesResponse && kycServicesResponse.data) {
				api.update(store => ({ ...store, kycServices: kycServicesResponse.data }));
			}

			loading = false;

			if(hasNft) {
				goto('/overview');
			}
		} catch (error) {
			console.error('Error initializing data:', error);
			loading = false;
		}
	});

	function handleStart() {
		goto('/overview');
	}
</script>

{#if loading}
	<LoadingScreen />
{:else}
	<PageWrapper customBackFunction="" headerTitle="" showHeaderBox={false}>
		<SplashScreenBox slot="information-content" />
		<SplashScreenButton slot="button-content" on:click={handleStart} />
	</PageWrapper>
{/if}
